body {
  margin: 0;
  color: #5a5a5a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url("./assets/background_lm.jpg") !important;*/
  /*background-size: cover !important;*/

  /*background-color: lightgray !important;*/
  /*background-blend-mode: lighten !important;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.embed-responsive-item {
  width: 100%;
  height: 100%;
}

.Collapsible {
  background-color: white;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #00ac9d;
  color: white;
}

.Collapsible__trigger:hover {
  color: red !important;
}

.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.small-modal-big-device {
  color: #5a5a5a;
  top: -40%;
}

.small-modal-small-device {
  color: #5a5a5a;
}

.small-modal-big-device > .modal-wrapper {
  height: 35%;
}

.wait-modal-big-device > .modal-wrapper {
  height: 40%;
}

.small-modal-small-device > .modal-wrapper {
  /*height: 22%;*/
}
